<template>
  <div>
    <div class="row mt-5 voucher-home">
      <div class="col-12 col-lg-6 offset-lg-3 dual-box text-white p-4">
        <b-overlay :show="loader" rounded="sm">
          <!-- <h3 class="mt-2">Register - Postcode</h3> -->

          <p>
            Please enter the first part of your postcode (eg NW1, NW1, MK14).
            <strong>Please note this is optional</strong> to help us improve our
            pharmacy coverage.
          </p>

          <div class="mb-3">
            <div class="async-error" v-if="this.error">
              {{ this.errorMessage }}
            </div>
            <b-form id="preassform" @submit.stop.prevent="onSubmit">
              <b-form-group id="email-input-group" label-for="email-input">
                <b-form-input
                  class="p-4"
                  id="email-input"
                  name="email-input"
                  v-model="form.postcode"
                  placeholder="Enter postcode"
                ></b-form-input>
              </b-form-group>
              <div class="row">
                <div class="col">
                  <button
                    class="btn btn-light btn-lg btn-block text-hpcolour-green"
                  >
                    Submit postcode
                  </button>
                </div>
                <div class="col">
                  <button
                    class="btn btn-light btn-lg btn-block text-hpcolour-green"
                  >
                    Skip postcode
                  </button>
                </div>
              </div>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        postcode: null,
      },
      loader: false,
      error: false,
      errorMessage: null,
    };
  },
  methods: {
    async onSubmit() {
      this.error = false;
      let postcode = this.form.postcode;
      if (postcode) {
        postcode = postcode.substring(0, 16).replace(/\s/g, "");
        localStorage.hpFluPostcode = postcode;
      }
      this.navigatePage("Email", {
        code: this.$route.params.code,
      });
    },
    navigatePage(page, params) {
      if (params) {
        this.$router.push({ name: page, params: params });
        return;
      }
      this.$router.push({ name: page });
    },
  },
};
</script>